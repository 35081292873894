import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Swagger = makeShortcode("Swagger");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "organisations-api-v3-beta-reference"
    }}>{`Organisations API V3 Beta Reference`}</h2>
    <p><a parentName="p" {...{
        "href": "http://petstore.swagger.io/?url=https://api.entur.io/organisations/v3-beta/api-docs"
      }}>{`See the API reference on the Swagger Petstore`}</a></p>
    <Swagger url="https://api.entur.io/organisations/v3-beta/api-docs" mdxType="Swagger" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      